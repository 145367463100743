<template>
  <div class="tw-relative">
    <slot />

    <div
      ref="cardRef"
      class="tw-h-full tw-overflow-hidden tw-flex tw-flex-col"
      :class="wrapperClass"
    >
      <div v-if="image" class="tw-mb-5" :class="imageClass">
        <img
          loading="lazy"
          :src="image"
          :alt="title"
          :title="title"
          class="tw-w-full tw-h-40 tw-object-cover"
        />
      </div>

      <p v-if="title" class="heading-h3 tw-line-clamp-3 tw-mb-2.5" :class="titleClass">
        {{ title }}
      </p>

      <p
        v-if="content"
        class="body-b1 tw-text-primary-300 js-content"
        :class="[contentClass, `tw-line-clamp-${numberOfLines}`]"
      >
        {{ content }}
      </p>

      <div class="tw-mt-auto">
        <div
          v-if="author"
          class="tw-flex tw-items-center tw-gap-1.5 tw-text-300 tw-leading-400 tw-mt-2.5"
        >
          <de-avatar :size="AVATAR_SIZE_OPTIONS.small" :src="author.image" />

          <span class="tw-inline-block tw-relative tw-z-10">
            {{ author.name }}
          </span>

          <i class="tw-w-100 tw-h-100 tw-inline-block tw-rounded-full tw-bg-primary-400" />

          <p class="tw-text-primary-300">
            {{ createdAt }}
          </p>
        </div>
      </div>
    </div>

    <nuxt-link-locale :to="href" class="tw-absolute tw-top-0 tw-w-full tw-h-full" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import DeAvatar from '~/shared/components/lib/avatar/DeAvatar.vue';
import { AVATAR_SIZE_OPTIONS } from '~/shared/components/lib/avatar/avatar.constant';
import type { ContentAuthor } from '~/shared/types/content.type';

defineProps<{
  href: string;
  image?: string | null;
  title?: string;
  content?: string | null;
  author?: ContentAuthor;
  createdAt?: string;
  wrapperClass?: string;
  contentClass?: string;
  imageClass?: string;
  titleClass?: string;
}>();

const cardRef = ref<HTMLElement | null>(null);
const numberOfLines = ref(3);

function calculateVisibleLines() {
  const card = cardRef.value;

  if (!card) return;

  const contentElement: HTMLElement | null = card.querySelector('.js-content');

  if (!contentElement) return;

  const lineHeightPx = getComputedStyle(contentElement).lineHeight;
  const lineHeight = parseFloat(lineHeightPx);

  // Calculate the total height of elements other than the content
  const totalOccupiedHeight: number = Array.from(card.children)
    .filter((elem): elem is HTMLElement => elem instanceof HTMLElement && elem !== contentElement)
    .reduce((acc: number, elem: HTMLElement) => {
      const styles = window.getComputedStyle(elem);

      if (styles.display === 'none') {
        return acc;
      }

      const marginTop = parseFloat(styles.marginTop);
      const marginBottom = parseFloat(styles.marginBottom);
      const totalHeight = elem.offsetHeight + marginTop + marginBottom;

      return acc + totalHeight;
    }, 0);

  const availableSpace = card.offsetHeight - totalOccupiedHeight;

  // Calculate number of visible lines in the content area
  // todo: check the threshold for browsser (if the result is something like 2.4 should it be 2 or 3?)
  numberOfLines.value = Math.round(availableSpace / lineHeight);
}

onMounted(() => {
  calculateVisibleLines();
});
</script>
